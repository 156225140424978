export default {
    name: "Home",
    data() {
        return {
            select: 0,
            window: window,
            //轮播图
            banner: [
                { url: require("../../assets/banner1.jpg") },
                { url: require("../../assets/banner2.jpg") },
                { url: require("../../assets/banner3.jpg") },
            ],
            //第一部分
            oneList: [{
                    url: require("../../assets/1.svg"),
                    text: "全国直营",
                    text1: "覆盖全国各大前沿城市",
                    text2: "全国连锁定点服务",
                },
                {
                    url: require("../../assets/2.svg"),
                    text: "医资雄厚",
                    text1: "百人医生团队",
                    text2: "持证上岗，经验丰富",
                },
                {
                    url: require("../../assets/3.svg"),
                    text: "植养一体",
                    text1: "自研不剃发植发®技术",
                    text2: "完善的术后养护体系",
                },
                {
                    url: require("../../assets/4.svg"),
                    text: "信誉服务",
                    text1: "植发服务细致化",
                    text2: "各个环节 想您所想",
                },

                {
                    url: require("../../assets/5.svg"),
                    text: "莲主任养护",
                    text1: "莲主任头皮抗衰体系",
                    text2: "呵护根根毛发",
                },
            ],
            //第二部分
            twoList: [{
                    url: require("../../assets/wap-index-lou1.png"),
                },
                {
                    url: require("../../assets/wap-index-lou2.png"),
                },
                {
                    url: require("../../assets/wap-index-lou3.png"),
                },
                {
                    url: require("../../assets/wap-index-lou4.png"),
                },
            ],
            dataList: [],
            data: [{
                title: "不剃发植发®技术简介",
                text: "碧莲盛不剃发植发®技术，是由杨志祥教授带领的碧莲盛植发技术研究院自主研发的新一代植发技术。",
            }, ],
            data2: [{
                    title: "不剃发植发®技术提取",
                    text: "在毛发移植过程中，毛囊提取区无需理发，可保留原发型。提取时，采用整发提取的方式，使用定制的取发器，在供发区间隔均匀、大面积、分散性的精准提取毛囊与发干。",
                },
                {
                    title: "不剃发植发®技术种植",
                    text: "在毛发移植过程中，种植区无需理发、保留原有发型，种植时，采用即插即种的方式，以原生发的密度、生长角度等信息进行参考，自然均匀的精准种植。",
                },
                {
                    title: "取发器设计",
                    text: "由传统剃发技术采用的多维空心环形立体钻，改成口径更为精细的摆动式喇叭口环钻，并将钻头设计为钝头，进而减少毛干转进取发器的几率。",
                },
                {
                    title: "取发器操作",
                    text: "不剃发植发®技术采用摆动式取发器，并设计了正反旋转豁口，操作时顺时针、逆时针往复交替，直至取发完成。",
                },
            ],
            data3: [{
                    title: "技术优势",
                    text: "通过植发术式与植发器械的双重改变，实现了长发提取、长发种植的目的，达到供体区原状态提取，种植区趋于自然的效果。",
                },
            ],
            data4: [{
                title: "适用人群",
                text: "形象要求高；即刻变美，无尴尬期；隐私保护需求高；超过9成的不剃发发友选择碧莲盛。（数据来源：2021中国植发行业报告）",
            }, ],
            twoPaging: [
                { page: 0, text: "技术背书" },
                { page: 1, text: "技术术式" },
                { page: 2, text: "技术优势" },
                { page: 3, text: "适用人群" },
            ],
            //第三部分
            threeList: [
                { url: require("../../assets/LC1.png"), text: "毛囊检测" },
                { url: require("../../assets/LC2.png"), text: "提取位置" },
                { url: require("../../assets/LC3.png"), text: "取毛囊笔" },
                { url: require("../../assets/LC4.png"), text: "毛囊提取" },
                { url: require("../../assets/LC5.png"), text: "毛囊" },
                { url: require("../../assets/LC6.png"), text: "种植微针" },
                { url: require("../../assets/LC7.png"), text: "毛囊种植" },
                { url: require("../../assets/LC8.png"), text: "植发后效果" },
            ],
            //底部
            bottomList: [{
                    url: require("../../assets/16.svg"),
                    text: " 2005年创立企业",
                },
                {
                    url: require("../../assets/32.svg"),
                    text: " 全国直营连锁",
                },
                {
                    url: require("../../assets/13.svg"),
                    text: " 13年连续参加世界植发大会",
                },
                {
                    url: require("../../assets/600.svg"),
                    text: " 600位医护团队",
                },
                {
                    url: require("../../assets/300.svg"),
                    text: " 16类植养方式",
                },
            ],
        };
    },
    methods: {
        touch(item) {
            this.$nextTick(() => {
                // console.log(item.page);
                // this.select = item.page;
                this.$refs.twoSpanButton.forEach((element, index) => {
                    console.log(element);
                    // return
                    if (index == item.page) {
                        element.className += " twoSpanButton1";
                    } else {
                        element.className = "twoSpanButton";
                    }
                });
                this.$refs.twoSpanText.forEach((element, index) => {
                    console.log(element);
                    // return
                    if (index == item.page) {
                        element.className += " twoSpanText1";
                    } else {
                        element.className = "twoSpanText";
                    }
                });

                // console.log(this.$refs.twoSpanButton[item.page]);
                if (item.page == "0") {
                    this.dataList = this.data;
                    this.$refs.imgBoxs.style.backgroundImage =
                        "url(/img/wap-index-lou1.8bb39c45.png)";
                } else if (item.page == "1") {
                    this.dataList = this.data2;
                    this.$refs.imgBoxs.style.backgroundImage =
                        "url(/img/wap-index-lou2.8bb39c45.png)";
                } else if (item.page == "2") {
                    this.dataList = this.data3;
                    this.$refs.imgBoxs.style.backgroundImage =
                        "url(/img/wap-index-lou3.8bb39c45.png)";
                } else if (item.page == "3") {
                    this.dataList = this.data4;
                    this.$refs.imgBoxs.style.backgroundImage =
                        "url(/img/wap-index-lou4.8bb39c45.png)";
                }
            });
        },
        //设置滑动切换轮播图
        swiperright(index) {
            //上一页
            this.$refs.carousel.prev();
            //设置幻灯片的索引
            this.$refs.carousel.setActiveItem(index - 1);
        },
        swiperleft(index) {
            //下一页
            this.$refs.carousel.next();
            this.$refs.carousel.setActiveItem(index + 1);
        },
    },
    created() {
        this.dataList = this.data;
    },
    mounted() {
        this.$refs.twoSpanText[0].className += " twoSpanText1";
        this.$refs.twoSpanButton[0].className += " twoSpanButton1";
    },
    components: {},
};