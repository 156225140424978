import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueTouch from 'vue-touch'
Vue.use(VueTouch, { name: 'v-touch' })
import './common/font/font.css'

import { Popup } from 'vant';
Vue.use(Popup);


Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
    router,
    render: h => h(App)
}).$mount('#app')