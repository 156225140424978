<template>
  <div id="app">
    <div id="nav">
      <div class="Logo">
        <img alt="Vue logo" src="./assets/logo.svg" class="Logo" />
      </div>
      <div style="width: 40px; height: 40px" @click="navShow" class="navButton">
        <img src="./assets/menu.svg" alt="" />
      </div>
      <van-popup v-model="show" position="top" :style="{ height: '75%' }" round>
        <div class="navBox">
          <div @click="navShow()">
            <img
              src="./assets/close.svg"
              style="
                float: right;
                margin-right: -1.6rem;
                margin-top: -1rem;
                width: 1.5rem;
                height: 1.5rem;
              "
            />
          </div>
          <div
            v-for="(item, index) in navList"
            :key="index"
            @click="show = false"
            class="navList"
          >
            <router-link :to="item.router">{{ item.text }}</router-link>
          </div>
          <div>
            <img
              src="./assets/menu-logo.png"
              style="
                margin-top: 1rem;
                width: 5rem;
                height: 4.81rem;
                margin-bottom: 2.75rem;
              "
            />
          </div>
        </div>
      </van-popup>
    </div>

    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      window: window,
      show: false,
      navList: [
        { router: "/", text: "植发首页" },
        /*{ router: "/Technology", text: "植发技术" },*/
        { router: "/Honor", text: "公司荣誉" },
        { router: "/Expert", text: "植发专家" },
        { router: "/Department", text: "全国院部" },
        { router: "/Bigevent", text: "碧莲盛大事件" },
      ],
    };
  },
  methods: {
    navShow() {
      if (this.show) {
        this.show = false;
      } else {
        this.show = true;
      }
    },
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  width: 93.3vw;
  height: 3.4vh;
  /* background: rgb(52, 124, 249); */
  background: transparent;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  z-index: 3001;
  padding: 1vh 4vw 1vh 2.7vw;
}
#nav a {
  font-weight: bold;
  color: black;
  opacity: 0.6;
}

#nav a.router-link-exact-active {
  color: #3b82e3;
  opacity: 1;
}
.navButton {
  margin-top: 0.5rem;
}
.navBox {
  background: #f9fcff;
  padding: 2.19rem 2.69rem 3.19rem 2.69rem;
  height: 68.4%;
}
.Logo {
  width: 5rem;
  /*height: 2.35rem;*/
  margin-top: .1rem;
  margin-left: .5rem;
  /* line-height: 2.35rem; */
}
.navList {
  width: 100%;
  height: 42px;
  font-family: PingFangSC-Medium;
  font-size: 18px;
  color: #333333;
  letter-spacing: 0;
  text-align: left;
  float: left;
  font-weight: 500;
  /* margin-bottom: 1.81rem; */
  border-bottom: 1px solid #e8eef8;
  line-height: 42px;
  padding: 1rem 0;
}

.textStyle {
  margin-left: 10px;
  width: 100%;
  height: 17px;
  font-family: PingFangSC-Medium;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 500;
  line-height: 20px;
}
.van-popup {
  background: #f9fcff !important;
}
</style>
